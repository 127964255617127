@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Poppins:ital,wght@0,300;0,400;0,500;1,200;1,300;1,400;1,500&family=Smooch+Sans:wght@300;400;500;600;700&display=swap');

@layer base {
    body{
        @apply font-[Poppins];
        @apply font-[sans-serif];
    }
    li{
        @apply px-4;
        @apply cursor-pointer;
        @apply no-underline;
    }

}

.dancing{
    font-family: "Dancing Script", cursive;
    font-weight: 700;
    font-size: 2.3rem;
}

.smooch{
    font-family: 'Smooch Sans', sans-serif;
}


.swiper-slide{
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  
.quick-link:hover{
    
        color: #fb7f7f!important;
    
}