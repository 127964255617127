.heroSlider .swiper-button-prev,
.heroSlider .swiper-button-next {
  color: #248275;
  opacity: 60%;
  transition: all 0.1s ease-in;
}
.heroSlider .swiper-button-prev:hover,
.heroSlider .swiper-button-next:hover {
  color: #248275;
  transform: scale(1.3);
  transition: all 0.1s ease-in;
}
.heroSlider .swiper-button-prev::after,
.heroSlider .swiper-button-next::after {
  font-size: 30px;
}

.heroSlider .swiper-pagination-bullet {
  background: #248275;
}

@media (max-width: 768px) {
  .heroSlider .swiper-button-prev:hover,
  .heroSlider .swiper-button-next:hover {
    color: #248275;
    transform: scale(1);
    transition: all 0.1s ease-in;
  }
}
